import {feedService} from "./feed.service";
import {toast} from "react-toastify";
import ToastWithButton from "../components/ToastWithButton";
import {feedRoutes} from "../routes";
import React from "react";
import i18next from "i18next";
import history from "../historyProvider";
import {mainService} from "./main.service";
import config from "react-global-configuration";
import {feedOperationService} from "./feedOperation.service";
import {errorService} from "./error.service";

let t = i18next.t.bind(i18next);

export const operationProgressService = {
    getOperationProgressSet,
    addProgress,
    removeProgress,
    startProgressStreams,
    getProgressStream,
    handleResponse,
    showErrorToast,
    cancelProgressStreams
};

const OPERATION_PROGRESS = 'operationProgress';

function getOperationProgressSet() {
    return JSON.parse(localStorage.getItem(OPERATION_PROGRESS)) || [];
}

function addProgress(feedId, reader) {
    let existingItems = getOperationProgressSet();
    if (!existingItems.includes(feedId)) {
        existingItems.push(feedId)
    }
    responseReaderMap[feedId] = reader;
    localStorage.setItem(OPERATION_PROGRESS, JSON.stringify(existingItems));
}

function removeProgress(feedId) {
    let existingItems = getOperationProgressSet();
    let index = existingItems.findIndex((it) => it === feedId);
    if (index !== -1) {
        existingItems.splice(index, 1)
    }
    delete responseReaderMap[feedId];
    localStorage.setItem(OPERATION_PROGRESS, JSON.stringify(existingItems));
}

function startProgressStreams() {
    let clientOperations = getOperationProgressSet();
    if (clientOperations.length > 0) {
        feedOperationService.getAllProcessing().then(operations => {
            let processingFeeds = operations.map(it => it.operation.feedId);
            clientOperations.forEach(guid => {
                if (processingFeeds.includes(guid)) {
                    getProgressStream(guid)
                } else {
                    operationProgressService.removeProgress(guid);
                }
            })
        });
    }
}

let responseReaderMap = {};

async function getProgressStream(guid, updater, onSuccessCallback, onErrorCallback) {
    const requestOptions = await mainService.getRequestOptionsWithAuth("GET");
    return fetch(`${config.get('API_URL')}/feed/progress/${guid}`, requestOptions)
        .then(response => {
            if (responseReaderMap[guid]) {
                responseReaderMap[guid].cancel();
                operationProgressService.removeProgress(guid);
            }
            operationProgressService.addProgress(guid, response.body.getReader());
            return handleResponse(guid, responseReaderMap[guid], updater, onSuccessCallback, onErrorCallback)
        })
        .catch((e) => {
            showErrorToast(e, guid);
            onErrorCallback && onErrorCallback(e);
        })
}

function showSuccessToast(feedId) {
    feedService.getPostImportSummary(feedId).then(summary => {
        if (summary.skippedRowCount > 0 || summary.modifiedRowCount > 0) {
            toast.warn(
                <ToastWithButton
                    text={t('feed.importIncompletelySuccess', {postProcess: 'sprintf', sprintf: [summary.url, summary.skippedRowCount, summary.modifiedRowCount]})}
                    buttonText={t`feed.goToMerchantOverview`}
                    onButtonClick={() => history.push({pathname: feedRoutes.list.path, state: { merchantGuid: summary.merchantId }})}
                />)
        } else {
            toast.success(
                <ToastWithButton
                    text={t('feed.importSuccess', {postProcess: 'sprintf', sprintf: [summary.url]})}
                    buttonText={t`feed.goToMerchantOverview`}
                    onButtonClick={() => history.push({pathname: feedRoutes.list.path, state: { merchantGuid: summary.merchantId  }})}
                />)
        }
    });
}

function showErrorToast(error, feedId) {
    let feedUrl;
    feedService.getByGuid(feedId).then(feed => {
        feedUrl = feed.feedUrl;

        toast.error(
            <ToastWithButton
                text={t('feed.importFailed', {postProcess: 'sprintf', sprintf: [feedUrl, errorService.getError(error).message]})}
                buttonText={t`feed.adjustFeedDetails`}
                onButtonClick={() => {
                    history.push({pathname: feedRoutes.upload.path, state: {feedGuid: feedId}})
                }}
            />);
    });
}

function handleResponse(feedId, reader, updater, onSuccessCallback, onErrorCallback) {
    const decoder = new TextDecoder();
    let lastData = {};
    function pump() {
        return reader.read().then(function(result) {
            if (!result.done) {
                const decoded = decoder.decode(result.value);
                let data = [];
                try {
                    data = decoded && decoded.split("\n")
                        .filter((progressString) => !!progressString)
                        .map((progressString) => JSON.parse(progressString));
                } catch (e) {}

                data.forEach((progressItem) => {
                    if (progressItem.code) {
                        showErrorToast(progressItem, feedId);
                        onErrorCallback && onErrorCallback(progressItem);
                    }
                    lastData = progressItem;
                    updater && updater(
                        ((progressItem.rowsProceeded * 100) / progressItem.totalRows).toFixed(2),
                        progressItem.rowsProceeded,
                        progressItem.totalRows
                    );
                })

                return pump();
            } else {
                if ((lastData.rowsProceeded) >= lastData.totalRows) {
                    if (lastData.totalRows < lastData.rowsProceeded) {
                        lastData.totalRows = lastData.rowsProceeded
                    }
                    updater && updater((100).toFixed(2), lastData.rowsProceeded, lastData.totalRows);
                    showSuccessToast(feedId);
                    onSuccessCallback && onSuccessCallback();
                    operationProgressService.removeProgress(feedId);
                }
            }
        })
    }
    return pump()
}

function cancelProgressStreams() {
    let processingFeeds = Object.keys(responseReaderMap);
    processingFeeds.forEach(guid => {
        responseReaderMap[guid].cancel();
        operationProgressService.removeProgress(guid)
    });
}

import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from 'react-global-configuration';
import configuration from './config';
import './i18n';
import {quillService} from "./services/quill.service";
import Keycloak from "keycloak-js";
import {authenticationService} from "./services/authentication.service";

config.set(configuration);

const keyCloak = new Keycloak({
    "realm": config.get('keycloakRealm'),
    "url": config.get('keycloakUrl'),
    "clientId": config.get('keycloakClientId'),
})

const user = authenticationService.getUserAuthentication();

export const keyCloakPromise = new Promise(async (resolve) => {
    await keyCloak.init({
        token: user?.token,
        refreshToken: user?.refreshToken,
    });

    resolve(keyCloak);
})

quillService.forceBaseHrefUrlInLinks();
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import {mainService} from "./main.service";
import moment from "moment";

const feedStatus = {
    ACTIVE: 'Active',
    ACTIVE_INCOMPLETE: 'ActiveIncomplete',
    FAILED: 'Failed',
    IMPORTING: 'Importing',
    DELETING: 'Deleting',
    ACTIVE_WITH_ERRORS: 'ActiveWithErrors',
};

const feedLastImportType = {
    FULL_IMPORT: 'FullImport',
    APPLY_DIFFERENCE: 'ApplyDifference',
}

export const feedService = {
    getInformation,
    validateNotDuplicateFeed,
    getAll,
    getAllGmc,
    getAllByMerchant,
    getByGuid,
    create,
    update,
    exportData,
    updateExportedFeed,
    exportAllData,
    exportFailedData,
    remove,
    getPostImportSummary,
    feedStatus,
    feedLastImportType,
    transformToFeedTableData,
    createGmc,
};

async function getInformation(url) {
    return mainService.performRequest('/feed/information', 'POST', {url: url});
}

async function validateNotDuplicateFeed(url, countryId, languageId, feedId) {
    return mainService.performRequest(
        `/feed/validate/${encodeURIComponent(url)}/${countryId}/${languageId}${feedId ? `/${feedId}` : ''}`,
        'GET'
    )
}

async function getAll() {
    return mainService.performRequest('/feed/list', 'GET');
}

async function getAllGmc() {
    return mainService.performRequest('/feed/list-gmc', 'GET');
}

async function getAllByMerchant(merchantGuid) {
    return mainService.performRequest(`/feed/list/${merchantGuid}`, 'GET');
}

async function getByGuid(guid) {
    return mainService.performRequest(`/feed/single/${guid}`, 'GET');
}

async function create(feed) {
    return mainService.performRequest('/feed/add', 'POST', feed);
}

async function update(feed) {
    return mainService.performRequest('/feed/update', 'POST', feed);
}

async function exportData(guid) {
    return mainService.performRequest('/feed/export/elastic', 'POST', {guid: guid});
}

async function updateExportedFeed(guid) {
    return mainService.performRequest('/feed/update-feed', 'POST', {guid: guid});
}

async function exportAllData() {
    return mainService.performRequest('/feed/export-all/elastic', 'POST', {});
}

async function exportFailedData() {
    return mainService.performRequest('/feed/export-failed/elastic', 'POST', {});
}

async function remove(guid) {
    return mainService.performRequest('/feed/delete', 'DELETE', {guid: guid});
}

async function getPostImportSummary(guid) {
    return mainService.performRequest(`/feed/post-import-summary/${guid}`, 'GET');
}

async function createGmc(feed) {
    return mainService.performRequest('/feed/add-gmc', 'POST', feed);
}

function transformToFeedTableData(feedData) {
    return feedData.map(feed => ({
        guid: feed.guid,
        status: feed.status,
        statusString: feed.status.type.replace("feed.FeedStatus.", ""),
        location: `${feed.currency.sign} | ${feed.country.code}`,
        merchantTitle: feed.merchant.title,
        languageName: feed.language.title,
        countryName: feed.country.name,
        currency: `${feed.currency.sign} | ${feed.currency.code}`,
        url: feed.url,
        productCount: feed.productCount,
        createdDate: moment.utc(feed.createdDate, "YYYY-MM-DD HH:mm:ss").local().format("L"),
        updatedDate: moment.utc(feed.updatedDate, "YYYY-MM-DD HH:mm:ss").local().format("L HH:mm"),
        uploaderUser: feed.uploaderUser !== null ? `${feed.uploaderUser.name} ${feed.uploaderUser.surname}` : '-',
        notificationUsers: feed.notificationUsers.map(user => `${user.name} ${user.surname}`).join(", "),
        feedUrl: feed.feedUrl,
        utmSource: feed.utmSource,
        utmMedium: feed.utmMedium,
        utmCampaign: feed.utmCampaign,
        enableUtm: feed.enableUtm,
        fieldMap: feed.fieldMap,
        refreshedDate: feed.refreshedDate ? moment.utc(feed.refreshedDate, "YYYY-MM-DD HH:mm:ss").local().format("L HH:mm"): '-',
        shouldUseFullImageFileName: feed.shouldUseFullImageFileName,
        lastImportOperationCount: feed.lastImportOperationCount,
        lastImportType: feed.lastImportType,
        lastImportTypeString: feed.lastImportType?.type.replace("feed.FeedLastImportType.", ""),
    })).sort((a, b) => b.productCount - a.productCount)
}

import { errorService} from "./error.service";
import cookie from "react-cookies";
import config from "react-global-configuration";
import {keyCloakPromise} from "../index";
import {userService} from "./user.service";

class MainService {
    async getRequestOptionsWithAuth(method, body) {
        const keyCloak = await keyCloakPromise

        try {
            await keyCloak.updateToken(30);
        } catch (e) {
            userService.logout();
            window.location = '#/login';

            throw new Error('Token expired');
        }

        const requestOptions = {
            method: method,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + keyCloak.token,
            },
        }

        if (method !== 'GET') {
            requestOptions['body'] = body || {}
        }

        return requestOptions
    }

    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok && response.status === 401) {
                cookie.remove('user')
                window.location = '#/login'
            }

            if (response.ok) {
                return data
            }

            const error = errorService.getError(data)
            return Promise.reject(error)
        });
    }

    performRequest(path, method, body) {
        return this.performExternalRequest(config.get('API_URL') + path, method, body)
    }

    async performExternalRequest(url, method, body) {
        const requestOptions = await this.getRequestOptionsWithAuth(method, JSON.stringify(body));

        return fetch(url, requestOptions).then(this.handleResponse)
    }
}

export const mainService = new MainService()

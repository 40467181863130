import React, { Component } from "react";

class LoadingSpinner extends Component {
  render() {
    return <div className="animated fadeIn pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"> </div>
    </div>
  }
}

export default LoadingSpinner;

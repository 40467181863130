import {mainService} from "./main.service";
import cookie from 'react-cookies';
import {operationProgressService} from "./operationProgress.service";

export const userService = {
    logout,
    getAllActive,
    getAllNotMerchantAccounts,
    getAllAvailableResponsibleUsers,
    getByGuid,
    isGranted,
    updateUserPermissions,
    getPermissionsAndDetails,
    canUserManageKeycloakUsers: canUserManageUsersInKeycloak,
    canUserAccessAnyResources,
};

function logout() {
    operationProgressService.cancelProgressStreams();
    localStorage.removeItem(cookie.load('user'));
    cookie.remove('user');
}

async function getAllActive() {
    return mainService.performRequest('/user/active-list', 'GET')
        .then(data => data.map(row => {
            return {
                ...row,
                fullName: `${row.name} ${row.surname}`
            }
        }));
}

async function getAllNotMerchantAccounts() {
    return mainService.performRequest('/user/not-merchant-accounts', 'GET');
}

async function getAllAvailableResponsibleUsers(merchantId) {
    return mainService.performRequest('/user/available-list/' + merchantId, 'GET');
}

async function getByGuid(guid) {
    return mainService.performRequest('/user/single/' + guid, 'GET');
}

function canUserAccessAnyResources() {
    return canUserManageUsersInKeycloak() || getCurrentUserPermissions().filter(function(permission) {
        return permission.granted
    }).length !== 0;
}

function isGranted($permissionName, $operation) {
    const permission = getCurrentUserPermissions().filter(function(item) { return item.name === $permissionName && item.operation === $operation; })[0];

    return permission && permission.granted;
}

function getCurrentUserPermissions() {
    const currentUser = getLocallyStoredUser();

    return currentUser?.permissions ?? [];
}

function canUserManageUsersInKeycloak() {
    const currentUser = getLocallyStoredUser();

    if (currentUser === null) {
        return false;
    }

    return currentUser.canManageUsersInKeycloak;
}

function getLocallyStoredUser() {
    const userCookie = cookie.load('user')
    if (!userCookie) {
        return null
    }
    return JSON.parse(localStorage.getItem(userCookie));
}

function updateUserPermissions(permissionGroupId, permissions) {
    const currentUser = JSON.parse(localStorage.getItem(cookie.load('user')));
    if (currentUser.user.permissionGroupId === permissionGroupId) {
        currentUser.permissions = permissions;
        localStorage.setItem(currentUser.user.guid, JSON.stringify(currentUser));
    }
}

function getPermissionsAndDetails() {
    return mainService.performRequest('/user/permissions-and-details', 'GET')
}

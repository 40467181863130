import React, {Component} from 'react'
import Button from "reactstrap/es/Button";

class ToastWithButton extends Component {

  render() {
    return  <>
      <div className={"word-break"}>
        {this.props.text}
      </div>
      <div className={"toast-button-container"}>
        <Button onClick={this.props.onButtonClick} className={"align-center"} color={"primary"} size={"sm"}>
          {this.props.buttonText}
        </Button>
      </div>
    </>
  }
}

export default ToastWithButton

import React, { Component } from 'react';
import {Router} from 'react-router-dom';
import './App.scss';
import LoadingSpinner from "./components/LoadingSpinner";
import AppSwitch from "./AppSwitch";
import history from "./historyProvider";

class App extends Component {

  render() {
    return (
      <Router history={history}>
          <React.Suspense fallback={<LoadingSpinner/>}>
            <AppSwitch/>
          </React.Suspense>
      </Router>
    );
  }
}

export default App;

import {userService} from "./user.service";
import cookie from "react-cookies";
import config from "react-global-configuration";
import {mainService} from "./main.service";
import {keyCloakPromise} from "../index";

export const userCookie = 'user';

export const authenticationService = {
    getUserAuthentication,
    storeUserAuthentication,
    ensureUserExists,
}

function getUserAuthentication() {
    return JSON.parse(localStorage.getItem(cookie.load(userCookie)));
}

async function storeUserAuthentication() {
    const keyCloak = await keyCloakPromise;
    const permissionsAndDetails = await userService.getPermissionsAndDetails();

    cookie.save(userCookie, permissionsAndDetails.user.guid);
    localStorage.clear();
    localStorage.setItem(
        permissionsAndDetails.user.guid,
        JSON.stringify({
            token: keyCloak.token,
            refreshToken: keyCloak.refreshToken,
            permissions: permissionsAndDetails.permissions,
            user: permissionsAndDetails.user,
            canManageUsersInKeycloak: permissionsAndDetails.canManageUsersInKeycloak,
        })
    );
}

async function ensureUserExists(accessToken) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + accessToken,
        },
    }

    return fetch(config.get('API_URL') + '/user/ensure-user-exists', requestOptions).then(mainService.handleResponse)
}

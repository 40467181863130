import {Quill} from 'react-quill';

export const quillService = {
    forceBaseHrefUrlInLinks
}

function forceBaseHrefUrlInLinks() {
    let Link = Quill.import('formats/link')
    class SeoLink extends Link {
        static sanitize(url) {
            return url.indexOf(":") > -1 ? "" : url;
        }
    }

    Quill.register('formats/link', SeoLink)
}

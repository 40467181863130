import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Fetch from 'i18next-fetch-backend'
import sprintf from 'i18next-sprintf-postprocessor';
import config from './config'

export const DEFAULT_TRANS_LANG = "DE"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Fetch)
  .use(sprintf)
  .init({
    backend: {
      loadPath: `${config.API_URL}/translation/map/${config.namespace.ADMIN}/{{lng}}`,
      allowMultiLoading: false,
      crossDomain: true,
    },
    saveMissing: false,
    lng: localStorage.getItem("language") || DEFAULT_TRANS_LANG,
    fallbackLng: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export const t = i18n.t.bind(i18n)

export default i18n;

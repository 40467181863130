import {mainService} from "./main.service";
import moment from "moment";
import _ from 'lodash'

const feedOperationStatus = {
    IN_QUEUE: 0,
    PROCESSING: 1,
    COMPLETED: 2,
    FAILED: 3
};

export const feedOperationService = {
    get,
    getAll,
    getAllProcessing,
    transformToTableData,
    feedOperationStatus,
    getStatusMessage,
    limitCompletedOperations
};

async function get(operationFeedGuid) {
    return mainService.performRequest(`/feed-operation/${operationFeedGuid}`, 'GET');
}

async function getAll() {
    return mainService.performRequest('/feed-operation/list', 'GET');
}

async function getAllProcessing() {
    return mainService.performRequest('/feed-operation/list-processing', 'GET');
}

function transformToTableData(feedOperations) {
    return feedOperations
        .sort((a, b) => b.feedOperationState.timestamp - a.feedOperationState.timestamp)
        .map(feed => _.assign(feed, {
            operationType: feed.feedOperationState.operation.type,
            date: moment.utc(feed.feedOperationState.timestamp).local().format('YYYY-MM-DD HH:mm:ss'),
        }))
}

function limitCompletedOperations(feedOperations, limit) {
    let completed = 0;
    return feedOperations.filter((operation) => {
        if (operation.status === feedOperationStatus.COMPLETED) {
            completed++;
        }
        return operation.status !== feedOperationStatus.COMPLETED || completed <= limit;
    })
}

function getStatusMessage(feedOperationTableRow) {
    return feedOperationTableRow.status === feedOperationStatus.FAILED
        ? feedOperationTableRow.feedOperationState.status.reason : ''
}

const config = {
  API_URL: 'https://stagebackend.smarketer.tools',
  ELASTIC_URL: 'https://stagebackend.smarketer.tools:5001',
  ELASTIC_INDEX: 'products',
  SHOP_URL: 'https://stage.smarketer.shopping',
  TOOLS_URL: '[TOOLS_URL]',
  repository: {
      LANGUAGE: 'LanguageRepository',
      MERCHANT: 'MerchantRepository',
      PERMISSION_GROUP: 'PermissionGroupRepository',
      TRANSLATION: 'TranslationRepository',
      USER: 'UserRepository',
      COUNTRY: 'CountryRepository',
      CURRENCY: 'CurrencyRepository',
      FIELD: 'FieldRepository',
      FEED: 'FeedRepository',
      USP_TEXT_FIELD: 'UspTextFieldRepository',
      TOP_CATEGORY: 'TopCategoryRepository',
      CATEGORY_FEED: 'CategoryFeedRepository',
      FEED_OPERATION: 'FeedOperationStateRepository',
      PRIVACY_PAGE: 'PrivacyPageRepository',
      IMPRINT_PAGE: 'ImprintPageRepository',
      CTA_BUTTON: 'CtaButtonRepository',
      LANDING_PAGE: 'LandingPageRepository',
      MERCHANT_ACCOUNT: 'MerchantAccountRepository',
      USER_PERMISSION_GROUP_RELATION: 'UserPermissionGroupRelationRepository',
      METADATA: 'MetadataRepository',
      HOMEPAGE: 'HomePageRepository',
      TOP_MERCHANT: 'TopMerchantRepository',
      TOP_BRAND: 'TopBrandRepository',
      TOP_SELLER: 'TopSellerRepository',
      GENERAL_SETTINGS: 'GeneralSettingsRepository',
      HEADER_BANNER: 'HeaderBannerRepository',
      GOOGLE_MERCHANT_CENTER_FEED: 'GoogleMerchantCenterFeedRepository',
      LANDING_PAGE_AD_BANNER: 'LandingPageAdBannerRepository',
      BENCHMARK_PRICE_FEED: 'BenchmarkPriceFeedRepository',
      BENCHMARK_PRICE_LEADER_URL_DEFINITION: 'BenchmarkPriceLeaderUrlDefinitionRepository',
      BENCHMARK_PRICE_EXCEPTION: 'BenchmarkPriceExceptionRepository',
      COUPON_CATEGORY: 'CouponCategoryRepository',
      FILTER_URL_DEFINITION: 'FilterUrlDefinitionRepository',
  },
  operation: {
    VIEW: 'View',
    FETCH: 'Fetch',
    CREATE: 'Create',
    UPDATE: 'Update',
    DELETE: 'Delete',
    EXECUTE: 'Execute'
  },
  namespace: {
    ADMIN: "admin",
    SHOP: "shop"
  },
  fileStorageUrl: {
    amazonS3: 'https://stage-smarketer.s3.eu-central-1.amazonaws.com',
    local: 'https://stagebackend.smarketer.tools/uploads',
  },
  keycloakUrl: 'https://stagebackend.smarketer.tools:8443/',
  keycloakRealm: 'smarketer',
  keycloakClientId: 'smarketer-tools',
};

export default config;

import React, { Component } from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import cookie from 'react-cookies';

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./examples/Pages/Login'));

class AppSwitch extends Component {

  render() {
    return <Switch>
      <Route path="/login(.*)" name="Login Page" render={props => <Login {...props}/>} />
        <Route path="/" name="Home" render={props => (
            localStorage.getItem(cookie.load('user'))
                ? <DefaultLayout {...props}/>
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
    </Switch>
  }
}

export default withTranslation()(AppSwitch)

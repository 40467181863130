import React from 'react'
import _ from 'lodash'

const Dashboard = React.lazy(() => import('./examples/Dashboard'))
const Users = React.lazy(() => import('./views/Users/Users'))
const Merchants = React.lazy(() => import('./views/Merchant/Merchants'))
const Feeds = React.lazy(() => import('./views/Feed/Feeds'))
const Merchant = React.lazy(() => import('./views/Merchant/Merchant'))
const Permissions = React.lazy(() => import('./views/Permissions/Permissions'))
const PermissionGroup = React.lazy(() => import('./views/Permissions/PermissionGroup'))
const Translations = React.lazy(() => import('./views/Translation/Translations'))
const FeedUpload = React.lazy(() => import('./views/Feed/FeedUpload'))
const Countries = React.lazy(() => import('./views/Country/Countries'))
const Country = React.lazy(() => import('./views/Country/Country'))
const Currencies = React.lazy(() => import('./views/Currency/Currencies'))
const Currency = React.lazy(() => import('./views/Currency/Currency'))
const Languages = React.lazy(() => import('./views/Language/Languages'))
const Language = React.lazy(() => import ('./views/Language/Language'))
const Fields = React.lazy(() => import('./views/Field/Fields'))
const Field = React.lazy(() => import('./views/Field/Field'))
const UspTextFields = React.lazy(() => import('./views/UspTextField/UspTextFields'))
const UspTextField = React.lazy(() => import('./views/UspTextField/UspTextField'))
const TopCategories = React.lazy(() => import('./views/TopCategory/TopCategories'))
const TopCategory = React.lazy(() => import('./views/TopCategory/TopCategory'))
const CategoryFeedList = React.lazy(() => import('./views/CategoryFeed/List'))
const CategoryFeedForm = React.lazy(() => import('./views/CategoryFeed/Form'))
const FeedOperations = React.lazy(() => import('./views/FeedOperation/List'));
const PrivacyPages = React.lazy(() => import('./views/ShopLegalPages/PrivacyPage/PrivacyPages'))
const PrivacyPage = React.lazy(() => import('./views/ShopLegalPages/PrivacyPage/PrivacyPage'))
const ImprintPages = React.lazy(() => import('./views/ShopLegalPages/ImprintPage/ImprintPages'))
const ImprintPage = React.lazy(() => import('./views/ShopLegalPages/ImprintPage/ImprintPage'))
const CtaButtons = React.lazy(() => import('./views/CtaButton/CtaButtons'))
const CtaButton = React.lazy(() => import('./views/CtaButton/CtaButton'))
const LandingPages = React.lazy(() => import('./views/LandingPage/LandingPages'))
const LandingPage = React.lazy(() => import('./views/LandingPage/LandingPage'))
const MerchantAccounts = React.lazy(() => import('./views/MerchantAccount/MerchantAccounts'))
const MerchantAccount = React.lazy(() => import('./views/MerchantAccount/MerchantAccount'))
const UserPermissionGroupRelations = React.lazy(() => import('./views/UserPermissionGroupRelation/UserPermissionGroupRelations'))
const UserPermissionGroupRelation = React.lazy(() => import('./views/UserPermissionGroupRelation/UserPermissionGroupRelation'))
const Metadatas = React.lazy(() => import('./views/Metadata/Metadatas'))
const Metadata = React.lazy(() => import('./views/Metadata/Metadata'))
const HomePages = React.lazy(() => import('./views/HomePage/HomePages'))
const HomePage = React.lazy(() => import('./views/HomePage/HomePage'))
const TopMerchants = React.lazy(() => import('./views/TopMerchant/TopMerchants'))
const TopMerchant = React.lazy(() => import('./views/TopMerchant/TopMerchant'))
const TopBrands = React.lazy(() => import('./views/TopBrand/TopBrands'))
const TopBrand = React.lazy(() => import('./views/TopBrand/TopBrand'))
const TopSellers = React.lazy(() => import('./views/TopSeller/TopSellers'))
const TopSeller = React.lazy(() => import('./views/TopSeller/TopSeller'))
const GeneralSettings = React.lazy(() => import('./views/GeneralSettings/GeneralSettings'))
const HeaderBanner = React.lazy(() => import('./views/HeaderBanner/HeaderBanner'))
const HeaderBanners = React.lazy(() => import('./views/HeaderBanner/HeaderBanners'))
const GoogleMerchantCenterFeeds = React.lazy(() => import('./views/GoogleMerchantCenterFeed/GoogleMerchantCenterFeeds'))
const GoogleMerchantCenterFeed = React.lazy(() => import('./views/GoogleMerchantCenterFeed/GoogleMerchantCenterFeed'))
const LandingPageAdBanners = React.lazy(() => import('./views/LandingPageAdBanner/LandingPageAdBanners'))
const LandingPageAdBanner = React.lazy(() => import('./views/LandingPageAdBanner/LandingPageAdBanner'))
const BenchmarkPriceFeedList = React.lazy(() => import('./views/BenchmarkPriceFeed/BenchmarkPriceFeedList'))
const BenchmarkPriceFeedForm = React.lazy(() => import('./views/BenchmarkPriceFeed/BenchmarkPriceFeedForm'))
const BenchmarkPriceLeaderUrlDefinitions = React.lazy(() => import('./views/BenchmarkPriceLeaderUrlDefinition/BenchmarkPriceLeaderUrlDefinitions'))
const BenchmarkPriceLeaderUrlDefinition = React.lazy(() => import('./views/BenchmarkPriceLeaderUrlDefinition/BenchmarkPriceLeaderUrlDefinition'))
const BenchmarkPriceExceptionList = React.lazy(() => import('./views/BenchmarkPriceException/BenchmarkPriceExceptionList'))
const BenchmarkPriceExceptionForm = React.lazy(() => import('./views/BenchmarkPriceException/BenchmarkPriceExceptionForm'))
const FilterUrlDefinitions = React.lazy(() => import('./views/FilterUrlDefinition/FilterUrlDefinitions'))
const FilterUrlDefinition = React.lazy(() => import('./views/FilterUrlDefinition/FilterUrlDefinition'))

export const categoryFeedRoutes = {
    list: { path: '/category-feed/', exact: true, name: `categoryFeed.list`, component: CategoryFeedList },
    create: { path: '/category-feed/create', exact: true, name: `categoryFeed.new`, component: CategoryFeedForm },
    update: { path: '/category-feed/update', exact: true, name: `categoryFeed.edit`, component: CategoryFeedForm },
    delete: { path: '/category-feed/delete' }
}

export const userRoutes = {
    list: { path: '/users', exact: true, name: `user.list`, component: Users },
}

export const merchantRoutes = {
    list: { path: '/merchants', exact: true, name: `merchant.list`, component: Merchants },
    create: { path: '/merchants/create', exact: true, name: `merchant.createNew`, component: Merchant },
    update: { path: '/merchants/edit', exact: true, name: `merchant.edit`, component: Merchant },
    feeds: { path: '/merchants/feeds', exact: true, name: `feed.list`, component: Feeds },
}

export const permissionRoutes = {
    list: { path: '/permissions', exact: true, name: `permission.list`, component: Permissions },
    create: { path: '/permission-group/create', exact: true, name: `permission.createNew`, component: PermissionGroup },
    update: { path: '/permission-group/edit', exact: true, name: `permission.edit`, component: PermissionGroup },
}

export const countryRoutes = {
    list: { path: '/countries', exact: true, name: `country.list`, component: Countries },
    create: { path: '/countries/create', exact: true, name: `country.createNew`, component: Country },
    update: { path: '/countries/edit', exact: true, name: `country.edit`, component: Country },
}

export const currencyRoutes = {
    list: { path: '/currencies', exact: true, name: `currency.list`, component: Currencies },
    create: { path: '/currencies/create', exact: true, name: `currency.createNew`, component: Currency },
    update: { path: '/currencies/edit', exact: true, name: `currency.edit`, component: Currency },
}

export const languageRoutes = {
    list: { path: '/languages', exact: true, name: `language.list`, component: Languages },
    create: { path: '/languages/create', exact: true, name: `language.createNew`, component: Language },
    update: { path: '/languages/edit', exact: true, name: `language.edit`, component: Language },
}

export const fieldRoutes = {
    list: { path: '/fields', exact: true, name: `field.list`, component: Fields },
    create: { path: '/fields/create', exact: true, name: `field.createNew`, component: Field },
    update: { path: '/fields/edit', exact: true, name: `field.edit`, component: Field },
}

export const uspTextFieldRoutes = {
    list: { path: '/usp-text-fields', exact: true, name: `uspTextField.list`, component: UspTextFields },
    create: { path: '/usp-text-fields/create', exact: true, name: `uspTextField.createNew`, component: UspTextField },
    update: { path: '/usp-text-fields/edit', exact: true, name: `uspTextField.edit`, component: UspTextField },
}

export const ctaButtonRoutes = {
    list: { path: '/cta-buttons', exact: true, name: `ctaButton.list`, component: CtaButtons },
    create: { path: '/cta-buttons/create', exact: true, name: `ctaButton.createNew`, component: CtaButton },
    update: { path: '/cta-buttons/edit', exact: true, name: `ctaButton.edit`, component: CtaButton },
}

export const topCategoryRoutes = {
    list: { path: '/top-categories', exact: true, name: `topCategory.list`, component: TopCategories },
    create: { path: '/top-categories/create', exact: true, name: `topCategory.createNew`, component: TopCategory },
    update: { path: '/top-categories/edit', exact: true, name: `topCategory.edit`, component: TopCategory },
}

export const privacyPageRoutes = {
    list: { path: '/privacy-pages', exact: true, name: `privacyPage.list`, component: PrivacyPages },
    create: { path: '/privacy-pages/create', exact: true, name: `privacyPage.createNew`, component: PrivacyPage },
    update: { path: '/privacy-pages/edit', exact: true, name: `privacyPage.editPage`, component: PrivacyPage },
}

export const imprintPageRoutes = {
    list: { path: '/imprint-pages', exact: true, name: `imprintPage.list`, component: ImprintPages },
    create: { path: '/imprint-pages/create', exact: true, name: `imprintPage.createNew`, component: ImprintPage },
    update: { path: '/imprint-pages/edit', exact: true, name: `imprintPage.edit`, component: ImprintPage },
}

export const feedRoutes = {
    list: { path: '/feeds', exact: true, name: `feed.list`, component: Feeds },
    upload: { path: '/feed/upload', exact: true, name: `feed.upload`, component: FeedUpload },
    operations: { path: '/feed-operations', exact: true, name: `feedOperation.list`, component: FeedOperations },
}

export const mainRoutes = {
    home: { path: '/', exact: true, name: `nav.home` },
    dashboard: { path: '/dashboard', name: `nav.dashboard`, component: Dashboard },
}

export const translationRoutes = {
    list: { path: '/translations', exact: true, name: `translation.list`, component: Translations }
}

export const landingPageRoutes = {
    list: { path: '/landing-pages', exact: true, name: `landingPage.list`, component: LandingPages},
    create: { path: '/landing-pages/create', exact: true, name: `landingPage.createNew`, component: LandingPage},
    update: { path: '/landing-pages/edit', exact: true, name: `landingPage.edit`, component: LandingPage}
}

export const merchantAccountRoutes = {
    list: { path: '/merchant-accounts', exact: true, name: `merchantAccount.list`, component: MerchantAccounts},
    create: { path: '/merchant-accounts/create', exact: true, name: `merchantAccount.createNew`, component: MerchantAccount},
    update: { path: '/merchant-accounts/edit', exact: true, name: `merchantAccount.edit`, component: MerchantAccount}
}

export const userPermissionGroupRelationRoutes = {
    list: { path: '/user-permission-group-relations', exact: true, name: `userPermissionGroupRelation.list`, component: UserPermissionGroupRelations},
    create: { path: '/user-permission-group-relations/create', exact: true, name: `userPermissionGroupRelation.createNew`, component: UserPermissionGroupRelation},
    update: { path: '/user-permission-group-relations/edit', exact: true, name: `userPermissionGroupRelation.edit`, component: UserPermissionGroupRelation}
}

export const metadataRoutes = {
    list: { path: '/metadatas', exact: true, name: `metadata.list`, component: Metadatas},
    create: { path: '/metadatas/create', exact: true, name: `metadata.createNew`, component: Metadata},
    update: { path: '/metadatas/edit', exact: true, name: `metadata.edit`, component: Metadata}
}

export const homePageRoutes = {
    list: { path: '/home-pages', exact: true, name: `homePage.list`, component: HomePages },
    create: { path: '/home-page/create', exact: true, name: `homePage.createNew`, component: HomePage },
    update: { path: '/home-page/edit', exact: true, name: `homePage.edit`, component: HomePage },
}

export const topMerchantRoutes = {
    list: { path: '/top-merchants', exact: true, name: `topMerchant.list`, component: TopMerchants },
    create: { path: '/top-merchant/create', exact: true, name: `topMerchant.createNew`, component: TopMerchant },
    update: { path: '/top-merchant/edit', exact: true, name: `topMerchant.edit`, component: TopMerchant },
}

export const topBrandRoutes = {
    list: { path: '/top-brands', exact: true, name: `topBrand.list`, component: TopBrands },
    create: { path: '/top-brand/create', exact: true, name: `topBrand.createNew`, component: TopBrand },
    update: { path: '/top-brand/edit', exact: true, name: `topBrand.edit`, component: TopBrand },
}

export const topSellerRoutes = {
    list: { path: '/top-sellers', exact: true, name: `topSeller.list`, component: TopSellers },
    create: { path: '/top-seller/create', exact: true, name: `topSeller.createNew`, component: TopSeller },
    update: { path: '/top-seller/edit', exact: true, name: `topSeller.edit`, component: TopSeller },
}

export const generalSettingsRoutes = {
    single: { path: '/general-settings', exact: true, name: `generalSettings.single`, component: GeneralSettings }
}

export const headerBannerRoutes = {
  list: { path: '/header-banners', exact: true, name: `headerBanner.list`, component: HeaderBanners },
  create: { path: '/header-banner/create', exact: true, name: `headerBanner.createNew`, component: HeaderBanner },
  update: { path: '/header-banner/edit', exact: true, name: `headerBanner.edit`, component: HeaderBanner },
}

export const googleMerchantCenterFeedRoutes = {
    list: { path: '/google-merchant-center-feeds', exact: true, name: `googleMerchantCenterFeed.list`, component: GoogleMerchantCenterFeeds },
    create: { path: '/google-merchant-center-feeds/create', exact: true, name: `googleMerchantCenterFeed.createNew`, component: GoogleMerchantCenterFeed },
    update: { path: '/google-merchant-center-feeds/edit', exact: true, name: `googleMerchantCenterFeed.edit`, component: GoogleMerchantCenterFeed },
}

export const landingPageAdBannerRoutes = {
    list: { path: '/landing-page-ad-banner', exact: true, name: `landingPageAdBanner.list`, component: LandingPageAdBanners },
    create: { path: '/landing-page-ad-banner/create', exact: true, name: `landingPageAdBanner.createNew`, component: LandingPageAdBanner },
    update: { path: '/landing-page-ad-banner/edit', exact: true, name: `landingPageAdBanner.edit`, component: LandingPageAdBanner },
}

export const benchmarkPriceFeedRoutes = {
    list: { path: '/benchmark-price-feed/', exact: true, name: `benchmarkPriceFeed.list`, component: BenchmarkPriceFeedList },
    create: { path: '/benchmark-price-feed/create', exact: true, name: `benchmarkPriceFeed.createNew`, component: BenchmarkPriceFeedForm },
    update: { path: '/benchmark-price-feed/edit', exact: true, name: `benchmarkPriceFeed.edit`, component: BenchmarkPriceFeedForm },
}

export const benchmarkPriceLeaderUrlDefinitionRoutes = {
    list: { path: '/benchmark-price-leader-url', exact: true, name: 'benchmarkPriceLeaderUrlDefinition.list', component: BenchmarkPriceLeaderUrlDefinitions },
    create: { path: '/benchmark-price-leader-url/create', exact: true, name: `benchmarkPriceLeaderUrlDefinition.createNew`, component: BenchmarkPriceLeaderUrlDefinition },
    update: { path: '/benchmark-price-leader-url/edit', exact: true, name: `benchmarkPriceLeaderUrlDefinition.edit`, component: BenchmarkPriceLeaderUrlDefinition },
}

export const benchmarkPriceExceptionRoutes = {
    list: { path: '/benchmark-price-exception', exact: true, name: 'benchmarkPriceException.list', component: BenchmarkPriceExceptionList },
    create: { path: '/benchmark-price-exception/create', exact: true, name: `benchmarkPriceException.createNew`, component: BenchmarkPriceExceptionForm },
}

export const filterUrlDefinitionRoutes = {
    list: { path: '/filter-url', exact: true, name: `filterUrlDefinition.list`, component: FilterUrlDefinitions},
    create: { path: '/filter-url/create', exact: true, name: `filterUrlDefinition.createNew`, component: FilterUrlDefinition},
    update: { path: '/filter-url/edit', exact: true, name: `filterUrlDefinition.edit`, component: FilterUrlDefinition},
}

const routes = [
    ..._.values(mainRoutes),
    ..._.values(translationRoutes),
    ..._.values(feedRoutes),
    ..._.values(userRoutes),
    ..._.values(merchantRoutes),
    ..._.values(permissionRoutes),
    ..._.values(countryRoutes),
    ..._.values(currencyRoutes),
    ..._.values(languageRoutes),
    ..._.values(fieldRoutes),
    ..._.values(uspTextFieldRoutes),
    ..._.values(ctaButtonRoutes),
    ..._.values(topCategoryRoutes),
    ..._.values(privacyPageRoutes),
    ..._.values(imprintPageRoutes),
    ..._.values(categoryFeedRoutes),
    ..._.values(landingPageRoutes),
    ..._.values(merchantAccountRoutes),
    ..._.values(userPermissionGroupRelationRoutes),
    ..._.values(metadataRoutes),
    ..._.values(homePageRoutes),
    ..._.values(topMerchantRoutes),
    ..._.values(topBrandRoutes),
    ..._.values(topSellerRoutes),
    ..._.values(generalSettingsRoutes),
    ..._.values(headerBannerRoutes),
    ..._.values(googleMerchantCenterFeedRoutes),
    ..._.values(landingPageAdBannerRoutes),
    ..._.values(benchmarkPriceFeedRoutes),
    ..._.values(benchmarkPriceLeaderUrlDefinitionRoutes),
    ..._.values(benchmarkPriceExceptionRoutes),
    ..._.values(filterUrlDefinitionRoutes),
];

export default routes

import i18next from 'i18next';
import { errorMessages } from "../errorMessages";

let t = i18next.t.bind(i18next);

export const errorService = {
    getError
};

function getError(error) {
    if (error.code && errorMessages.get(error.code)) {
        return {code: error.code, message: t(errorMessages.get(error.code))}
    } else {
        return {code: null, message: typeof error === 'object' ? error.message : error}
    }
}
